<template>
  <div class="breadcrumbs">
    <div class="container">
      <Breadcrumbs></Breadcrumbs>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Breadcrumb',
  data() {
    return {}
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/components/component-breadcrumbs.scss';

</style>
